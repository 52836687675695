<section class="section contact" id="store">    

  <div class="floating-icon" *ngIf="!ShowFloating" (click)="openPreview(content)">
    <div class="icon-link" >
      <i class="fas fa-shopping-cart"></i>
    </div>
  </div>

  <div class="container">

    <ng-template #content let-modal>
        <div class="modal-header">
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div *ngIf="success" class="alert alert-success">
          <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}
      </div>
      <div *ngIf="error"  class="alert alert-danger">
        <strong>Sorry!</strong>  There is an issue in sending your Order enquiry. Please try again or contact "+91-96295 86991"
    </div>
        <div class="modal-body" id="invoice-container">
            <div class="container mt-4">
              <div class="row justify-content-left" style="display: flex; margin: 30px 2px;">  
                <div class="col-md-auto" style="padding: 0px;">
                  <div class="logo">
                    <img src='assets/images/me/LOGO.png' width='85px' height="100px" />
                  </div>
                </div>          
                <div class="col-md-auto"  style="padding: 0px;">
                  <div><h1>Cherish Crackers</h1></div>
                  <div class="add">
                    2/3261, Opposite to karuman Transport, Viswanatham, Sivakasi-626189
                  </div>
                  <div class="add">ph: 97904 73547</div>
                </div> 
                
              </div>
              <div class="row">
                <div class="col-6">
                  <h3>Billing Details - #{{randomNum}}</h3>
                </div>
                <div class="col-6">Total: Rs. {{ getTotalPrice() }} <b>(50% discount) = Rs. {{getDiscountPrice()}}</b></div>
              </div>
               
                <table class="table table-bordered invoice" >
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Sub Name</th>
                      <th>Discount Price</th>
                      <th>Count</th>
                      <th>Total</th>
                      <th class="removetrash">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of selectedMain;let i = index">
                      <td>{{ i + 1 }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.subName }}</td>
                      <td>Rs. {{ discountPrice(item.Price) }}</td>
                      <td class="hideInMail" style="width: 10%;">
                        <input
                        id="removetrash"
                        style="width: 100%;"
                        (change)="onCheckboxfromPreview(item.id,countInput.value)"
                          type="number" 
                          name="count" 
                          min="1" 
                          step="1" 
                        value="{{ item.count }}"
                          #countInput
                        />
                      </td>
                      <td class="showInMail">{{item.count }}</td>
                      <td>Rs. {{ discountPrice(item.Price) * item.count }}</td>
                      <td class="removetrash" (click)="trash(item.id)"><i class="fa fa-trash"></i></td>
                    </tr>
                  </tbody>
                </table>
                <div class="CD">
                  <div class="cd">Customer Details</div>
                  <div>Name: {{customerName}}</div>
                  <div>phone Number: {{customerPhoneNumber}} </div>
                  <div>address: {{customerAddress}} </div>
                </div>
               
              </div>
        </div>
        <div class="modal-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-3">
                <label for="name">Name:</label>
                <input type="text" id="name" [(ngModel)]="customerName">
              </div>
              <div class="col-md-3">
                <label for="phoneNumber">Phone Number:</label>
                <input type="text" id="phoneNumber" [(ngModel)]="customerPhoneNumber" inputmode="numeric">
              </div>
              <div class="col-md-3">
                <label for="address">Address:</label>
                <input id="address" [(ngModel)]="customerAddress">
              </div>
              <div class="col-md-3">
                <label for="email">Email:</label>
                <input id="email" [(ngModel)]="customerEmail">
              </div>
              <div *ngIf="success" class="alert alert-success">
                <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}. Please check your email for customer copy.
            </div>
            <div *ngIf="error"  class="alert alert-danger">
              <strong>Sorry!</strong>  There is an issue in sending your Order enquiry. Please try again or contact "+91-97904 73547"
          </div>
          <div *ngIf="downloadSuccess" class="alert alert-success">
            <strong>Success!</strong> your Order Enquiry sent Successfully invoice id #{{randomNum}}
        </div>
            </div>

          </div>
          <div *ngIf="showErrorMsg">
            please enter the customer details
          </div>
          <button type="button" *ngIf="isDesktopDevice" class="btn btn-outline-dark" (click)="downloadInvoiceAsPDF()">Invoice</button>
          <button type="button" class="btn btn-outline-dark" (click)="sendEmail()">Sent Mail</button>          
        </div>
    </ng-template>
    
    
    
    <hr />
    <div class="section-box">
      <div class="storeArea">
        <div class="tabbed-container">
            
           <div class="row">
            <div class="col-6 right">
                <h2 class="mhead">Start your Shopping here </h2>
            </div>
            
            <div class="col-3 preview"> <button type="button"
                class="btn btn-outline-primary" (click)="openPreview(content)">Cart </button></div>
            <div class="col-3 preview"> <button  type="button"
                class="btn btn-outline-primary" (click)="clearBtn()">Clear </button>
            </div>
            
          
           </div>
           <ul class="list-unstyled" style="margin-top: 1rem;">
            <li>How it Works!
              <ul>
                <li>&#9642; Please Mention your Full name, contact number, Address where you want the crackers to be delivered</li>
                <li>&#9642; Once you submitted your enquiry our executive will call you within 12 hours</li>
                <li>&#9642; Your orders will be verified with them and payment details will be shared</li>
                <li>&#9642; All of the orders will delivered via reputated parcel services near by your location.</li>
                <li>&#9642; As per Supreme Court Law, crackers should not be sold online. So this is the procedure we have to follow</li>
                <li>&#9642; Delivery cost varies depends on the order. For orders more than Rs.5000, Free delivery will be done</li>
              </ul>
            </li>    
          </ul>
           
          <div class="nav nav-tabs">
            <div class="nav-item" (click)="showTab('tab1')">
              <a class="nav-link" [class.active]="activeTab === 'tab1'"
                >Select your items</a
              >
            </div>
            <!-- <div class="nav-item" (click)="showTab('tab2')">
              <a class="nav-link" [class.active]="activeTab === 'tab2'"
                >Fancy Crackers</a
              >
            </div>
            <div class="nav-item" (click)="showTab('tab3')">
              <a class="nav-link" [class.active]="activeTab === 'tab3'"
                >Ground Crackers</a
              >
            </div>                         -->

          </div>

          <div class="tab-content">
            <div *ngIf="activeTab === 'tab1'" class="">
              <ul>
                <li  class="mainName"  *ngFor="let key of groundCrackersKeys; let i = index">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="toggleGroundCollapse(i)"
                    [attr.aria-expanded]="!isGroundCollapsed[i]"
                    aria-controls="collapseExample"
                  >
                  <img class="avImg" src="../../../../assets/images/me.svg" alt="Image Description">
                    {{ key }}
                    <i style="padding: 18px;" class="fa fa-chevron-down"></i>
                  </button>

                  <div [ngbCollapse]="isGroundCollapsed[i]" class="card">
                    <div class="card-body">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Actual price</th>
                            <th scope="col discountPrice">Discount price</th>                            
                            <th scope="col">Quantity</th>
                            <th scope="col">availablity</th>
                          </tr>
                        </thead>
                        <ng-container
                          *ngFor="
                            let subCategory of fireworksData.GroundCrackers[key]
                              | keyvalue
                          "
                        >
                          <tbody>
                            <tr>
                              <td>{{ subCategory.key }}</td>

                              <td class="actualPrice"> Rs. {{ subCategory.value.Price }}</td>
                              <td  style="width: 15%;" class="discountPrice"> Rs. {{discountPrice(subCategory.value.Price) }}</td>                              
                              <td style="width: 10%;">
                                <input
                                style="width: 100%;"
                                (change)="onCheckboxChange(getUniqueIdentifier(key, subCategory),0,key,subCategory,countInput.value,true)"
                                  type="number" 
                                  name="count" 
                                  min="1" 
                                  step="1" 
                                  
                                  #countInput
                                />
                              </td>
                            
                              <td style="float: left">
                                <i
                                  style="color: green"
                                  *ngIf="
                                    subCategory.value.availablity == 'true'
                                  "
                                  class="fa fa-check"
                                ></i
                                ><i
                                  *ngIf="
                                    subCategory.value.availablity != 'true'
                                  "
                                  class="fa fa-ban"
                                ></i>
                              </td>
                            </tr>
                          </tbody>
                        </ng-container>
                      </table>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- second start-->
              <ul>
                <li class="mainName"  *ngFor="let key of skyCrackersKeys; let i = index">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="toggleSkyCollapse(i)"
                    [attr.aria-expanded]="!isSkyCollapsed[i]"
                    aria-controls="collapseExample"
                  >
                  <img class="avImg" src="../../../../assets/images/me.svg" alt="Image Description">
                  {{ key }}
                  <i style="padding: 18px;" class="fa fa-chevron-down"></i>
                  </button>

                  <div [ngbCollapse]="isSkyCollapsed[i]" class="card">
                    <div class="card-body">
                        <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Actual price</th>
                                <th scope="col discountPrice">Discount price</th>                                
                                <th scope="col">Count</th>

                                <th scope="col">availablity</th>
                              </tr>
                            </thead>
                            <ng-container
                              *ngFor="
                                let subCategory of fireworksData.SkyCrackers[key]
                                  | keyvalue
                              "
                            >
                              <tbody>
                                <tr>
                                    <td>{{ subCategory.key }}</td>
                                    <td class="actualPrice">Rs.  {{ subCategory.value.Price }}</td>
                                    <td  style="width: 15%;" class="discountPrice">Rs.  {{discountPrice(subCategory.value.Price) }}</td>                                    
                                    <td style="width: 10%;">
                                      <input
                                      style="width: 100%;"
                                      (change)="onCheckboxChange(getUniqueIdentifier(key, subCategory),1,key,subCategory,countInput.value,true)"
                                        type="number" 
                                        name="count" 
                                        min="1" 
                                        step="1" 
                                      
                                        #countInput
                                      />
                                    </td>
                              
                                    <td style="float: left">
                                      <i
                                        style="color: green"
                                        *ngIf="
                                          subCategory.value.availablity == 'true'
                                        "
                                        class="fa fa-check"
                                      ></i
                                      ><i
                                        *ngIf="
                                          subCategory.value.availablity != 'true'
                                        "
                                        class="fa fa-ban"
                                      ></i>
                                    </td>
                                  </tr>
                              </tbody>
                            </ng-container>
                          </table>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- second end -->
              <!-- third start -->
              <ul>
                <li class="mainName" *ngFor="let key of fancyCrackersKeys; let i = index">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="toggleFancyCollapse(i)"
                    [attr.aria-expanded]="!isFancyCollapsed[i]"
                    aria-controls="collapseExample"
                  >
                  <img class="avImg" src="../../../../assets/images/me.svg" alt="Image Description">
                  {{ key }}
                  <i style="padding: 18px;" class="fa fa-chevron-down"></i>
                  </button>
              
                  <div [ngbCollapse]="isFancyCollapsed[i]" class="card">
                    <div class="card-body">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                 
                            <th scope="col">Product</th>
                            <th scope="col">Actual price</th>
                            <th scope="col discountPrice">Discount price</th>                                
                            <th scope="col">Count</th>
                           
                            <th scope="col">availablity</th>
                          </tr>
                        </thead>
                        <ng-container
                          *ngFor="let subCategory of fireworksData.FancyCrackers[key] | keyvalue"
                        >
                          <tbody>
                            <tr>
                                <td>{{ subCategory.key }}</td>
                                <td class="actualPrice">Rs. {{ subCategory.value.Price }}</td>
                                <td style="width: 15%;" class="discountPrice">Rs. {{discountPrice(subCategory.value.Price) }}</td>                                    
                                <td style="width: 10%;">
                                  <input
                                  style="width: 100%;"
                                  (change)="onCheckboxChange(getUniqueIdentifier(key, subCategory),2,key,subCategory,countInput.value,true)"
                                    type="number" 
                                    name="count" 
                                    min="1" 
                                    step="1" 
                                  
                                    #countInput
                                  />
                                </td>
                               
                                <td style="float: left">
                                  <i
                                    style="color: green"
                                    *ngIf="
                                      subCategory.value.availablity == 'true'
                                    "
                                    class="fa fa-check"
                                  ></i
                                  ><i
                                    *ngIf="
                                      subCategory.value.availablity != 'true'
                                    "
                                    class="fa fa-ban"
                                  ></i>
                                </td>
                              </tr>
                          </tbody>
                        </ng-container>
                      </table>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- third end -->
          
            </div>
            <!-- <div *ngIf="activeTab === 'tab1'" class="">
              <ul>
                <li class="mainName"  *ngFor="let key of skyCrackersKeys; let i = index">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="toggleSkyCollapse(i)"
                    [attr.aria-expanded]="!isSkyCollapsed[i]"
                    aria-controls="collapseExample"
                  >
                    {{ key }}
                    <i class="fa fa-chevron-down"></i>
                  </button>

                  <div [ngbCollapse]="isSkyCollapsed[i]" class="card">
                    <div class="card-body">
                        <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Product</th>
                                <th scope="col">Actual price</th>
                                <th scope="col discountPrice">Discount price</th>                                
                                <th scope="col">Count</th>
                                <th scope="col">Buy</th>
                                <th scope="col">availablity</th>
                              </tr>
                            </thead>
                            <ng-container
                              *ngFor="
                                let subCategory of fireworksData.SkyCrackers[key]
                                  | keyvalue
                              "
                            >
                              <tbody>
                                <tr>
                                    <td>{{ subCategory.key }}</td>
                                    <td class="actualPrice">Rs.  {{ subCategory.value.Price }}</td>
                                    <td  style="width: 15%;" class="discountPrice">Rs.  {{discountPrice(subCategory.value.Price) }}</td>                                    
                                    <td style="width: 10%;">
                                      <input
                                      style="width: 100%;"
                                      (change)="onCheckboxChange(getUniqueIdentifier(key, subCategory),1,key,subCategory,countInput.value,false)"
                                        type="number" 
                                        name="count" 
                                        min="1" 
                                        step="1" 
                                      
                                        #countInput
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        (change)="onCheckboxChange(getUniqueIdentifier(key, subCategory),1,key,subCategory,countInput.value,true)"
                                      />
                                    </td>
                                    <td style="float: left">
                                      <i
                                        style="color: green"
                                        *ngIf="
                                          subCategory.value.availablity == 'true'
                                        "
                                        class="fa fa-check"
                                      ></i
                                      ><i
                                        *ngIf="
                                          subCategory.value.availablity != 'true'
                                        "
                                        class="fa fa-ban"
                                      ></i>
                                    </td>
                                  </tr>
                              </tbody>
                            </ng-container>
                          </table>
                    </div>
                  </div>
                </li>
              </ul>
            </div> -->
            <!-- <div *ngIf="activeTab === 'tab2'" class="">
                <ul>
                    <li class="mainName" *ngFor="let key of fancyCrackersKeys; let i = index">
                      <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="toggleFancyCollapse(i)"
                        [attr.aria-expanded]="!isFancyCollapsed[i]"
                        aria-controls="collapseExample"
                      >
                        {{ key }}
                        <i class="fa fa-chevron-down"></i>
                      </button>
                  
                      <div [ngbCollapse]="isFancyCollapsed[i]" class="card">
                        <div class="card-body">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                     
                                <th scope="col">Product</th>
                                <th scope="col">Actual price</th>
                                <th scope="col discountPrice">Discount price</th>                                
                                <th scope="col">Count</th>
                                <th scope="col">Buy</th>
                                <th scope="col">availablity</th>
                              </tr>
                            </thead>
                            <ng-container
                              *ngFor="let subCategory of fireworksData.FancyCrackers[key] | keyvalue"
                            >
                              <tbody>
                                <tr>
                                    <td>{{ subCategory.key }}</td>
                                    <td class="actualPrice">Rs. {{ subCategory.value.Price }}</td>
                                    <td style="width: 15%;" class="discountPrice">Rs. {{discountPrice(subCategory.value.Price) }}</td>                                    
                                    <td style="width: 10%;">
                                      <input
                                      style="width: 100%;"
                                      (change)="onCheckboxChange(getUniqueIdentifier(key, subCategory),2,key,subCategory,countInput.value,false)"
                                        type="number" 
                                        name="count" 
                                        min="1" 
                                        step="1" 
                                      
                                        #countInput
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        (change)="onCheckboxChange(getUniqueIdentifier(key, subCategory),2,key,subCategory,countInput.value,true)"
                                      />
                                    </td>
                                    <td style="float: left">
                                      <i
                                        style="color: green"
                                        *ngIf="
                                          subCategory.value.availablity == 'true'
                                        "
                                        class="fa fa-check"
                                      ></i
                                      ><i
                                        *ngIf="
                                          subCategory.value.availablity != 'true'
                                        "
                                        class="fa fa-ban"
                                      ></i>
                                    </td>
                                  </tr>
                              </tbody>
                            </ng-container>
                          </table>
                        </div>
                      </div>
                    </li>
                  </ul>
                  
            </div> -->
            <div *ngIf="activeTab === 'tab4'" class="">
              <ul>
                <li class="mainName"  *ngFor="let key of groundCrackersKeys; let i = index">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="toggleCollapse(i)"
                    [attr.aria-expanded]="!isCollapsed[i]"
                    aria-controls="collapseExample"
                  >
                    {{ key }}
                  </button>

                  <div [ngbCollapse]="isCollapsed[i]" class="card">
                    <div class="card-body">
                      <li
                        *ngFor="
                          let item of fireworksData.GroundCrackers.FlowerPots
                            | keyvalue
                        "
                      >
                        Name: {{ item.key }} - Price: {{ item.value.Price }} -
                        Availability: {{ item.value.availablity }}
                      </li>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</section>
