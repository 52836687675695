import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AboutComponent } from './about/about.component';
import { BannerComponent } from './banner/banner.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactComponent } from './contact/contact.component';
import {TabsModule} from "ngx-tabs";
import { MoreProyectsComponent } from './more-proyects/more-proyects.component';
import { ProyectsComponent } from './proyects/proyects.component';
import { NgbModule, NgbNav, NgbNavModule,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule, Routes } from '@angular/router';
import { StoreComponent } from './store/store.component'; 
import { FormsModule } from '@angular/forms';


export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const routes: Routes = [
  { path: '', redirectTo: '/tab1', pathMatch: 'full' }, // Default route
];


@NgModule({
  declarations: [
    HomeComponent,
    BannerComponent,
    AboutComponent,
    ProyectsComponent,
    MoreProyectsComponent,
    ContactComponent,
    StoreComponent
  ],
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    NgbNavModule,
    FormsModule,
    NgbModule,
    CarouselModule,
    NgbCollapseModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    
    
    

  ],
  exports:[RouterModule]
})
export class HomeModule { }
