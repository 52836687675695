
<div class="container footer" id="footerArea">      
       
    <div class="row justify-content-center">
        <div class="col-3 mobileHide" style="padding-left: 4%;">
            <img class="unitrix" src="assets/images/me/LOGO.png" style="width: 50%">
            <div style="font-size: 16px;">Join us in celebrating the simple joy of crunching, and let our crackers elevate your every occasion</div>
            
        </div>
        <div class=" col-xs-12 col-xl-1 col-lg-1 col-md-1 "> </div>
        <div class=" col-xs-12 col-xl-2 col-lg-2 col-md-2 "> 
            <div class="head"><p> Quick links</p> </div>
            <div> <p>Facebook</p></div>
            <div> <p>Instagram</p></div>            
        </div>
        <!-- <div class="col-xs-12 col-xl-4 col-lg-4 col-md-4 "> 
            <div class="head"><p>Cherish Crackers</p> </div>
            <div> <p><fa-icon [icon]="faLocationDot"></fa-icon>3/766,B-1, Opposite karuman transport,vetrilaiyurani Road Sivakasi,
                Tamil Nadu - 626 189</p></div>                    
            <div> <p> <fa-icon [icon]="faPhone"></fa-icon><a [href]="'tel:' + '7200582944'">7200-582-944</a></p></div>                                 
            <div> <p> <fa-icon [icon]="faPhone"></fa-icon><a [href]="'tel:' + '9047887717'">9047 887717</a></p></div>      
            <div (click)="mailMe()"> <p><fa-icon [icon]="faEnvelope"></fa-icon>cherishcrackers@gmail.com</p></div>                   
        </div>        -->
        <div class=" col-xs-12 col-xl-2 col-lg-2 col-md-2 "> </div>
        <!-- <div class="col-xs-12 col-xl-2 col-lg-2 col-md-2 "> 
            <div class="head"><p>Location</p> </div>
            <div><a href="https://www.google.com/maps/@9.4452415,77.8061399,3a,75y,56.94h,97.62t/data=!3m6!1e1!3m4!1sl0zQTb1Qu1yGNnDEsS4Vlg!2e0!7i13312!8i6656?entry=ttu" target="_blank"><img src="/assets/company/qrcode.png" height="120px" width="120px" alt=""></a></div>
            
        </div>         -->
        <!-- <div class="col-xs-0 col-xl-1 col-lg-1 col-md-1 ">  -->

        <!-- </div> -->
    </div>
</div>

<div class="company">
    <div class="com">
        Copyright ©2005-2023 Cherish Crackers. All rights reserved. | Privacy Policy | <span [routerLink]="'/terms'" class="terms">Terms of Service</span>
    </div>
</div>
