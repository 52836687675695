<section class="section banner">
    <img src="../../../../assets/img/sale.svg" alt="SVG Image" />

    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <!-- <h1>Hola, mi nombre es</h1> -->
                    <h1>Welcome to Cherish !</h1>
                </div>
                <div class='banner-title'>
                    <h2 class="mobileh2">
                        Diwali is happening everywhere, so let's spread love and kindness by sending warm wishes to everyone</h2>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a  routerLink="shop" class="main-btn">
                    {{"Banner.ActionBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>
