import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IpserviceService {

  constructor(
    private httpClient: HttpClient
  ) { }


 
  getFireworksData() {
    // Assuming the JSON file is in the assets folder
    return this.httpClient.get('/assets/fireworks-data.json');
  }
}
