import { Component } from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { IpserviceService } from "src/app/services/ipservice/ipservice.service";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { DeviceDetectorService } from 'ngx-device-detector';



@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.scss"],
})
export class StoreComponent {
  collapsed: true;
  ShowFloating: boolean = false;
  isGroundCollapsed: boolean[] = [];
  isSkyCollapsed: boolean[] = [];
  isFancyCollapsed: boolean[] = [];
  deviceInfo = null;
  activeTab: string = "tab1";
  groundCrackersKeys: string[];
  fireworksData: any;
  showErrorMsg: boolean = false;
  GroundCrackers: any;
  isDesktopDevice:any;
  randomNum: any;
  skyCrackers: any;
  closeResult = "";
  fancyCrackers: any;
  success: boolean = false;
  error: boolean = false;
  skyCrackersKeys: string[];
  fancyCrackersKeys: string[];
  customerName: string = "";
  customerPhoneNumber: string = "";
  customerAddress: string = "";
  isSendDisabled: boolean = true;
  downloadSuccess: boolean = false;
  invoiceUrl: string = "";
  customerEmail:string = "";

  selected1: any[] = [];
  selected2: any[] = [];
  selected3: any[] = [];
  selected4: any[] = [];

  selectedMain: any = [];

  showTab(tabName: string) {
    this.activeTab = tabName;
  }

  getTotalPrice(): number {
    let totalPrice = 0;

    for (const item of this.selectedMain) {
      totalPrice += item.Price * item.count;
    }

    return totalPrice;
  }

  getDiscountPrice() {
    let totalPrice = 0;

    for (const item of this.selectedMain) {
      totalPrice += item.Price * item.count;
    }

    const discountPercentage: number = 0.5; // 50% discount

    // Calculate the discounted price
    const discountedPrice: number = totalPrice * (1 - discountPercentage);

    return discountedPrice;
  }

  discountPrice(price) {
    const discountPercentage: number = 0.5; // 50% discount

    // Calculate the discounted price
    const discountedPrice: number = price * (1 - discountPercentage);

    return discountedPrice;
  }

  getinvoiceElementID() {
    const element = document.getElementById("invoice-container") as HTMLElement;
    element.style.border = "none";
    return element;
  }

  downloadInvoiceAsPDF(): void {
    const data: any = this.getinvoiceElementID();
    const imgWidth = 595; // A4 page width in pixels
    const pageHeight = 842; // A4 page height in pixels
    const padding = 20; // White space at the bottom of each page in pixels
  
    html2canvas(data).then(canvas => {
      const content = canvas.toDataURL('image/jpeg', 0.6);
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let pdf = new jspdf.jsPDF({
        orientation: 'portrait', // Use 'landscape' for landscape orientation
        unit: 'px', // Set units to pixels
        format: [imgWidth, pageHeight] // Set the dimensions in pixels (A4: 595 x 842)
      });
  
      let y = 10; // Set the initial Y-coordinate in pixels
      let pageData = canvas.height;
      let additionalPage = false;
  
      while (pageData > 0) {
        const remainingPageData = Math.min(pageData, pageHeight - padding);
        if (additionalPage) {
          pdf.addPage();
          additionalPage = false;
        }
        pdf.addImage(content, 'JPEG', 0, y, imgWidth, imgHeight);
        pageData -= remainingPageData;
        y -= pageHeight;
  
        if (pageData > 0) {
          additionalPage = true;
        }
      }
  
      const currentDateTime = moment().format('DD/MM/YYYY HH:mm:ss');      
      const currentDateTimeStr = currentDateTime.replace(/[^a-zA-Z0-9]/g, '');
      pdf.save('invoice_' + currentDateTimeStr + '.pdf');
  
      const blob = pdf.output('blob');
      this.invoiceUrl = URL.createObjectURL(blob);
      console.log(this.invoiceUrl);
    });
  }
  
   
  checkFields() {
    // Check if all three fields are filled
    const result =
      this.customerName != "" &&
      this.customerPhoneNumber != "" &&
      this.customerAddress != "" &&
      this.customerEmail != ""


    return result;
  }

  async getInvoiceAsBase64(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const data: any = this.getinvoiceElementID();
    const imgWidth = 595; // A4 page width in pixels
    const pageHeight = 842; // A4 page height in pixels
    const padding = 20; // White space at the bottom of each page in pixels
  
    html2canvas(data).then(canvas => {
      const content = canvas.toDataURL('image/jpeg', 0.6);
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let pdf = new jspdf.jsPDF({
        orientation: 'portrait', // Use 'landscape' for landscape orientation
        unit: 'px', // Set units to pixels
        format: [imgWidth, pageHeight] // Set the dimensions in pixels (A4: 595 x 842)
      });
  
      let y = 10; // Set the initial Y-coordinate in pixels
      let pageData = canvas.height;
  
      while (pageData > 0) {
        if (pageData !== canvas.height) {
          pdf.addPage();
        }
        const remainingPageData = Math.min(pageData, pageHeight - padding);
        pdf.addImage(content, 'JPEG', 0, y, imgWidth, imgHeight);
        pageData -= pageHeight - padding;
        y -= pageHeight + padding; // Add white space for separation
      }
      const pdfData = pdf.output('datauristring');
      resolve(pdfData);
      
    });
    });
  }
  
  


  generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Example usage

  getRandomNumber() {
    this.randomNum = this.generateRandomNumber(1, 10000); // Generate a random number between 1 and 100
    console.log("Random Number:", this.randomNum);
  }

  async sendEmail() {
     
    const removeInvoiceElements =
      document.getElementsByClassName("removetrash");

    for (let i = 0; i < removeInvoiceElements.length; i++) {
      (removeInvoiceElements[i] as HTMLElement).style.display = "none";
    }


    if (!this.checkFields()) {
      this.showErrorMsg = true;
    } else {
   
        
         const cssStyles = `
        <style>
          img {
            display: none;
          }
          table {
            padding: 0 5px;
            padding-top: 20px;
          }
          tr {
            padding: 20px 10px;
          }
          td {
            padding: 10px 25px;
          }
          th {
            text-align: left;
            padding-left: 25px;
          }
          .row {
            padding-left: 30px;
          }
          .CD {
            padding: 10px 30px;
          }
          .cd {
            font-weight: 600;
            padding-bottom: 5px;
          }
          tr {
            text-align: left !important;
          }
          .hideInMail{
            display: none;
          }
          .showInMail{
            display: block;
          }
        </style>
      `;
      

        
      

      this.showErrorMsg = false;

      const from = "infounitrix@gmail.com";

      let base = await this.getInvoiceAsBase64();

      let to = ["cherishcrackers@gmail.com" , this.customerEmail] // owner email
      let cc = this.customerEmail; //customer email
      
      let subject = "Order Received! Order ID: " + this.randomNum;
      let text =
        "Order Received from Customer Name " +
        this.customerName +
        " Customer Mobile Number " +
        this.customerPhoneNumber +
        " Delivery Address: " +
        this.customerAddress;

      const element = this.getinvoiceElementID();
      const elementHtml = element.innerHTML;
      const fullHtml = `<html><head>${cssStyles}</head><body>${elementHtml}</body></html>`;

      const data = {
        to: to,
        subject: subject,
        text: text,
        cc:cc,
        from:  from,
        base: base,
        html: fullHtml
      };
  
      // Define the URL of your Cloud Function
      const cloudFunctionUrl = 'https://us-central1-cherishcracker.cloudfunctions.net/sendEmail';
  
      this.http.post(cloudFunctionUrl, data)
        .subscribe(
          (response) => {
            this.success = true;
            // setTimeout(() => {
            //   this.success = false;
            // }, 5000);
            console.log('Email sent successfully', response);
          },
          (error) => {
            this.error = true;
            // setTimeout(() => {
            //   this.error = false;
            // }, 5000);
            console.error('Error sending email:', error);
          }
        );


    }
  }


  trash(id) {
 
    const existingIndex = this.selectedMain.findIndex((item) => item.id === id);

    if (existingIndex !== -1) {
      this.selectedMain.splice(existingIndex, 1);
    }
  }

  openPreview(content) {
    this.ShowFloating = true;
    this.getRandomNumber();
    this.selectedMain = [
      ...this.selected1,
      ...this.selected2,
      ...this.selected3,
    ];
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        size: "lg",
        windowClass: "modal-xl",
      })
      .result.then(
        (result) => {
          this.ShowFloating = false;
          this.closeResult = `Closed with: ${result}`;
          this.selectedMain;
          this.update();
        },
        (reason) => {
          this.ShowFloating = false;
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.update();
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  toggleGroundCollapse(index: number) {
    this.isGroundCollapsed[index] = !this.isGroundCollapsed[index];
  }
  toggleSkyCollapse(index: number) {
    this.isSkyCollapsed[index] = !this.isSkyCollapsed[index];
  }
  toggleFancyCollapse(index: number) {
    this.isFancyCollapsed[index] = !this.isFancyCollapsed[index];
  }

  constructor(
    private ipserviceService: IpserviceService,
    private modalService: NgbModal,
    private http: HttpClient,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
      const isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      this.isDesktopDevice = this.deviceService.isDesktop();
    this.ipserviceService.getFireworksData().subscribe((data: any) => {
      this.fireworksData = data;
      this.getJson();
      console.log(this.fireworksData);
    });
  }

  getJson() {
    this.GroundCrackers = this.fireworksData.GroundCrackers;
    this.groundCrackersKeys = Object.keys(this.GroundCrackers);

    // Initialize isCollapsed array with false values
    // this.isGroundCollapsed = new Array(this.groundCrackersKeys.length).fill(
    //   true
    // );

    this.skyCrackers = this.fireworksData.SkyCrackers;
    this.skyCrackersKeys = Object.keys(this.skyCrackers);

    // Initialize isCollapsed array with false values
    // this.isSkyCollapsed = new Array(this.skyCrackersKeys.length).fill(true);

    this.fancyCrackers = this.fireworksData.FancyCrackers;
    this.fancyCrackersKeys = Object.keys(this.fancyCrackers);

    // Initialize isCollapsed array with false values
    // this.isFancyCollapsed = new Array(this.fancyCrackersKeys.length).fill(true);
  }
  getMain(value) {
    let name = "";
    if (value == 0) {
      name = "SkyCrackers";
    } else if (value == 1) {
      name = "FancyCrackers";
    } else if (value == 2) {
      name = "GroundCrackers";
    } else if (value == 3) {
      name = "Gift Box";
    }
    return name;
  }

  onCheckboxfromPreview(id, count) {
    const existingIndex = this.selectedMain.findIndex((item) => {
      if (item.id === id) {

        item.count = count;

      }
    });
  }

  onCheckboxChange(UniqueIdentifier, main, name, sub, count, fromBuy) {

    if (main == 0) {
      const obj = {};
      let mainName = this.getMain(main);
      obj["id"] = UniqueIdentifier;
      obj["main"] = mainName;
      obj["name"] = name;
      obj["subName"] = sub.key;
      obj["Price"] = sub.value.Price;
      obj["count"] = count;

      const existingIndex = this.selected1.findIndex(
        (item) => item.id === UniqueIdentifier
      );

      if (existingIndex !== -1) {
        if (fromBuy) {
          this.selected1.splice(existingIndex, 1);
        } else {
          this.selected1[existingIndex] = obj;
        }

        //
      } else {
        if (fromBuy) {
          this.selected1.push(obj);
        } else {
          this.update();
        }
      }
    }

    if (main == 1) {
      const obj = {};
      let mainName = this.getMain(main);
      obj["id"] = UniqueIdentifier;
      obj["main"] = mainName;
      obj["name"] = name;
      obj["subName"] = sub.key;
      obj["Price"] = sub.value.Price;
      obj["count"] = count;

      const existingIndex = this.selected2.findIndex(
        (item) => item.id === UniqueIdentifier
      );

      if (existingIndex !== -1) {
        if (fromBuy) {
          this.selected2.splice(existingIndex, 1);
        } else {
          this.selected2[existingIndex] = obj;
        }

        //
      } else {
        if (fromBuy) {
          this.selected2.push(obj);
        } else {
          this.update();
        }
      }
    }
    if (main == 2) {
      const obj = {};
      let mainName = this.getMain(main);
      obj["id"] = UniqueIdentifier;
      obj["main"] = mainName;
      obj["name"] = name;
      obj["subName"] = sub.key;
      obj["Price"] = sub.value.Price;
      obj["count"] = count;

      const existingIndex = this.selected3.findIndex(
        (item) => item.id === UniqueIdentifier
      );

      if (existingIndex !== -1) {
        if (fromBuy) {
          this.selected3.splice(existingIndex, 1);
        } else {
          this.selected3[existingIndex] = obj;
        }

        //
      } else {
        if (fromBuy) {
          this.selected3.push(obj);
        } else {
          this.update();
        }
      }
    }
  }

  update() {
    this.selectedMain = [
      ...this.selected1,
      ...this.selected2,
      ...this.selected3,
    ];
  }

  clearBtn() {
    (this.selectedMain = []),
      (this.selected1 = []),
      (this.selected2 = []),
      (this.selected3 = []);
    this.router.navigate(["/reload"]);
  }
  getUniqueIdentifier(key, subCategory) {
    // You can concatenate key and subCategory values to create a unique identifier
    // For example:
    return key + "_" + subCategory.key;
  }
}
