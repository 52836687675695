<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline" data-toggle="tooltip" data-placement="left" title="Ensure that you and everyone around you maintain a safe distance from where the crackers are being ignited. A minimum of 5-10 meters is recommended for most fireworks.">Keep a safe distance </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline" data-toggle="tooltip" data-placement="left" title="Children should never handle fireworks without adult supervision. Designate a responsible adult to oversee the lighting of crackers.">Adult supervision </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline" data-toggle="tooltip" data-placement="left" title="Wear safety goggles to protect your eyes and have a bucket of water or a fire extinguisher nearby to put out any unexpected fires.">Protective gear </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline" data-toggle="tooltip" data-placement="left" title="Place fireworks on a flat, stable surface, away from flammable materials and buildings. Never ignite them indoors.">Flat, stable surface </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline" data-toggle="tooltip" data-placement="left" title="Light one firework at a time and move away quickly. Do not try to relight a firework that doesn't ignite properly.">One at a time </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline" data-toggle="tooltip" data-placement="left" title="Never handle fireworks while under the influence of alcohol or drugs. Impaired judgment can lead to accidents.">Stay sober </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline" data-toggle="tooltip" data-placement="left" title="Familiarize yourself with local laws and regulations regarding fireworks. Follow them strictly.">Respect local laws </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline" data-toggle="tooltip" data-placement="left" title="Keep pets indoors during fire">Pets </span></li>                            
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-container">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'  width="300" height="300" src="assets/images/me/LOGO.png" alt="Git User">
                        <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'  width="200" height="200" src="assets/images/offer.png" alt="Git User">
                    </div>                    
                </div>

            </div>
        </div>
    </div>
</section>
